<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="10">
        <el-col :xs="10" :sm="10" :md="6" :lg="4" :xl="4">
          <el-input v-model="params.keyWords" @change="getUserList" clearable placeholder="请输入商家名称"> </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="1">
          <el-button type="primary" class="margin-left20" @click="getUserList">搜索</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data.sync="tableList" border>
      <el-table-column prop="aliId" label="商家ID" min-width="200" align="center"></el-table-column>
      <el-table-column prop="companyName" label="商家名称" min-width="200" align="center"></el-table-column>
      <el-table-column prop="shopUrl" label="店铺地址" min-width="120" align="center"></el-table-column>
      <el-table-column label="添加时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="200" align="center">
        <template slot-scope="scope">
          <el-switch @change="handleSwitch(scope.row)" v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160" align="center">
        <template slot-scope="scope">
          <el-link @click="handleRefreshToken(scope.row)" :underline="false" type="primary">重新授权</el-link>
          <el-link @click="handleGetGoodsList(scope.row)" :underline="false" type="primary">同步商品</el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import testImg from '@/assets/images/test.jpg'
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { alibabaAuthorizationShop, alibabaAuthorizationShopStatus, alibabaRefreshToken, alibabaGetList, aLiBabaAuth } from '@/api/AlibabaController'
export default {
  components: {
    TitleBarBaseVue
  },
  data() {
    return {
      tableList: [],
      value: false,
      params: {
        keyWords: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    async handleGetGoodsList(row) {
      console.log(row)
      let [err, res] = await alibabaGetList({ aliId: row.aliId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '同步商品失败')
      }
      this.$message.success(res.msg || '正在同步商品中...')
    },
    handleRefreshToken() {
      window.open(aLiBabaAuth({ route: `${location.origin}` }))
    },
    async handleSwitch(row) {
      let params = {
        aliId: row.aliId,
        status: row.status
      }
      console.log(params)
      let [err, res] = await alibabaAuthorizationShopStatus(params)
      if (err) {
        console.log(err)
        row.status = !row.status
        return this.$message.error(err.msg || '操作失败')
      }
    },
    async getUserList() {
      let [err, res] = await alibabaAuthorizationShop(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      this.tableList = res.data
    }
  }
}
</script>
<style lang="less" scoped>
.el-link {
  margin: 0 10px;
}
</style>
